<template>
  <div>
    <AppBarLanding app></AppBarLanding>
    <!-- HERO VIDEO -->
    <v-container fluid class="ma-0 pa-0 mb-4">
      <v-row :style="'height: 500px;'">
        <v-col cols="12">
          <div class="video-container">
            <video
              autoplay
              loop
              muted
              playsinline
              preload="auto"
              poster="@/assets/video/landing_thumb.jpg"
            >
              <source src="@/assets/video/landing.mp4" type="video/mp4" />
            </video>
            <div class="video-overlay font-size-hero">
              <v-container fluid>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <h1 class="poppins800">Trenuj z VIVEFIT</h1>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" md="6">
                    <template>
                      <v-btn
                        :ripple="false"
                        elevation="1"
                        color="#fff"
                        class="font-weight-bolder font-size-hero py-4 px-7 ms-2"
                        :class="'btn-primary button-primary-gradient'"
                        to="/signup"
                        :style="'text-transform: none;'"
                        rounded
                        large
                      >
                        Zaczynamy
                      </v-btn>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- HERO RESPONSIVE -->
    <v-container class="bg-tranparent mb-4 mt-16">
      <v-row>
        <v-col
          class="pa-4 d-flex justify-end"
          cols="10"
          offset="1"
          md="5"
          offset-md="1"
        >
          <v-img
            src="@/assets/img/responsive.png"
            contain
            :style="'max-height: 400px !important;'"
          >
          </v-img>
        </v-col>
        <v-col
          cols="10"
          offset="1"
          md="5"
          offset-md-right="1"
          class="d-flex align-center"
        >
          <v-card class="bg-transparent font-size-hero-medium">
            <v-container fluid>
              <v-row class="d-flex">
                <span class="montserrat500 font-size-hero"
                  >Treningi, Wyzwania i Fit Przepisy
                </span>
              </v-row>
              <v-row class="d-flex">
                <span class="montserrat500 text-typo"
                  >Korzystaj bez ograniczeń na wielu urządzeniach.<br />
                  Aplikacja ViveFit jest zawsze z Tobą!
                </span>
              </v-row>
              <v-row class="d-flex mt-8">
                <span class="montserrat500 text-typo"
                  >Chcesz schudnąć?<br />
                  Wyrzeźbić ciało?<br />
                  Pozbyć się bólu kręgosłupa?<br />
                  Wrócić do formy po ciąży?
                </span>
              </v-row>
              <v-row class="d-flex mt-8">
                <span class="montserrat500 text-typo"
                  >Znajdziesz tu wszystko czego potrzebujesz,<br />
                  żeby uzyskać efekt o jakim marzysz!
                </span>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- HERO CHALLENGES -->
    <v-container class="mt-16 font-size-hero-medium bg-hero">
      <v-row class="mt-4">
        <v-col class="text-center">
          <h1 class="poppins600 text-center text-typo">
            Wybierz swój cel i podejmij wyzwanie
          </h1>
          <p class="text-body text-center">
            Motywujące plany treningowe skrojone na miarę potrzeb i poziomu
            zaawansowania.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="bg-hero pb-7">
      <v-row>
        <v-col cols="12">
          <slick-carousel v-bind="carousel">
            <v-card
              v-for="challenge in challenges"
              :key="challenge.id"
              class="transparent pa-1"
              @click="goToPricing"
            >
              <v-img :src="challenge.src" elevation="1" class="rounded">
              </v-img>
            </v-card>
          </slick-carousel>
        </v-col>
      </v-row>
    </v-container>

    <!-- HERO RECIPES -->
    <v-container id="recipes" class="mt-16 font-size-hero-medium bg-hero">
      <v-row class="mt-4">
        <v-col class="text-center">
          <h1
            class="poppins600 text-center text-typo"
            :style="'color: #4a4a4a;'"
          >
            Ponad 250 fit przepisów
          </h1>
          <p class="text-body text-center">
            Pyszne, szybkie i bardzo łatwe w przygotowaniu dania. Dieta kojarzy
            Ci się wyrzeczeniami? Te przepisy to zmienią! Ciesz się smacznym
            jedzeniem i piękną sylwetką. Zero nudy- wybieraj spośród bogatej
            bazy dań. Znajdziesz tutaj również przepisy wegańskie, bez glutenu,
            bez nabiału oraz bezmięsne.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="bg-hero">
      <v-row>
        <v-col cols="12">
          <slick-carousel v-bind="recipes_carousel">
            <v-card
              v-for="recipe in recipes"
              :key="recipe.title"
              class="transparent pa-1"
              @click="goToPricing"
            >
              <v-img :src="recipe.src" elevation="1" class="rounded"> </v-img>
            </v-card>
          </slick-carousel>
        </v-col>
      </v-row>
    </v-container>

    <!-- HERO TRAININGS -->
    <v-container id="trainings" class="mt-16 font-size-hero-medium bg-hero">
      <v-row class="mt-4">
        <v-col class="text-center">
          <h1 class="poppins600 text-center text-typo">
            Ponad 100 filmów z treningami
          </h1>
          <p class="text-body text-center">
            Różnorodne i skuteczne treningi na każdym poziomie zaawansowania.
            Znajdziesz tu wszystko co lubisz- ćwiczenia modelujące, cardio,
            pilates, HIIT, treningi siłowe, zdrowy kręgosłup, stretching,
            taneczne i wiele innych.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="bg-hero mb-4">
      <v-row>
        <v-col cols="12">
          <slick-carousel v-bind="carousel">
            <v-card
              v-for="training in trainings"
              :key="training.id"
              @click="goToPricing"
              class="transparent pa-1"
            >
              <v-img :src="training.src" elevation="1" class="rounded"> </v-img>
            </v-card>
          </slick-carousel>
        </v-col>
      </v-row>
    </v-container>

    <!-- HERO METAMORFOZY -->
    <v-container class="mt-16 font-size-hero-medium bg-hero">
      <v-row class="mt-4">
        <v-col class="text-center">
          <h1 class="poppins600 text-center text-typo">
            Wasze przemiany
            <v-icon :style="'color: #EC69FF; font-size: 35px !important;'"
              >fas fa-heart</v-icon
            >
          </h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="bg-hero mb-4">
      <v-row>
        <v-col cols="12">
          <slick-carousel v-bind="carousel">
            <v-card
              v-for="meta in metamorfozy"
              :key="meta.id"
              class="transparent pa-1 meta-card"
            >
              <v-img :src="meta.src" elevation="1" class="rounded"> </v-img>
              <span class="meta-overlay text-center"
                ><span class="meta-name">{{ meta.name }}</span
                ><br />{{ meta.text }}</span
              >
            </v-card>
          </slick-carousel>
        </v-col>
      </v-row>
    </v-container>

    <!-- HERO ABOUT ME -->
    <v-container class="bg-tranparent mb-4 mt-16">
      <v-row>
        <v-col
          class="d-flex justify-end"
          cols="10"
          offset="1"
          md="5"
          offset-md="1"
        >
          <v-img src="@/assets/img/about_me.png" contain> </v-img>
        </v-col>
        <v-col cols="10" offset="1" md="5" offset-md-right="1">
          <v-card class="bg-transparent font-size-hero">
            <v-container fluid>
              <v-row class="mt-4">
                <h4
                  class="montserrat500 text-left font-size-hero-medium"
                  :style="'color: rgba(81, 81, 81, 0.87) !important;'"
                >
                  <span class="font-size-hero text-black">Hej!</span
                  ><br /><br />

                  Jestem dyplomowaną trenerką fitness i tańca.<br />
                  Przez 15 lat prowadziłam zajęcia w klubie fitness, a od 7 lat
                  propaguję zdrowy styl życia w sieci.<br /><br />

                  On-line trenuje ze mną tysiące kobiet i mężczyzn w każdym
                  wieku.<br /><br />

                  Chcę Ci pokazać, że fitness to nie tylko ćwiczenia i dieta- to
                  STYL ŻYCIA, dzięki któremu wyglądasz i czujesz się
                  świetnie.<br /><br />

                  Prywatnie jestem mamą energicznego 2 latka- Maxa i psią mamą
                  Dextera, puchatego szpica, który często towarzyszy mi w
                  treningach.<br /><br />

                  Nie wyobrażam sobie życia bez treningów, tańca i dobrego
                  jedzenia ;)<br /><br />

                  Dołącz do mnie i VIVE FIT
                  <br /><br />
                  <span class="pacifico font-size-hero"
                    >Paula Piotrzkowska</span
                  >
                </h4>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- HERO PRICING -->
    <payment-plans id="pricing" isLanding></payment-plans>

    <!-- FAQ -->
    <v-container class="bg-hero mt-16">
      <v-row>
        <v-col md="8" class="mt-16 mx-auto text-center">
          <h2 class="text-typo text-center text-h2 font-weight-bolder mb-2">
            Masz pytania
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="10" class="mx-auto">
          <v-expansion-panels flat color="#ddd">
            <v-expansion-panel
              v-for="que in faq"
              :key="que.title"
              class="bg-transparent"
            >
              <v-expansion-panel-header>
                <h5 class="text-h5 font-weight-bold text-typo">
                  {{ que.title }}
                </h5>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="text-body text-sm opacity-8">
                  {{ que.description }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <!-- FOOTER -->
    <v-container class="">
      <Footer></Footer>
    </v-container>
  </div>
</template>

<script>
import AppBarLanding from "@/components/landing/AppBarLanding.vue";
import Footer from "@/components/Footer.vue";
import PaymentPlans from "@/components/util/PaymentPlans.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Landing",
  data() {
    return {
      trainings: [
        {
          id: 0,
          src: "https://cdn.filestackcontent.com/Di0StXFjTBilmyen7Rcz",
        },
        {
          id: 1,
          src: "https://cdn.filestackcontent.com/vekqY025RIGMTeitiW4R",
        },
        {
          id: 2,
          src: "https://cdn.filestackcontent.com/FZaJx05rTW2cfrELFKgF",
        },
        {
          id: 3,
          src: "https://cdn.filestackcontent.com/LHiP6rqYTDKIGqPlgerJ",
        },
        {
          id: 5,
          src: "https://cdn.filestackcontent.com/P9Q100TLS1y0aHHEZuBV",
        },
        {
          id: 6,
          src: "https://cdn.filestackcontent.com/4A6EoWirRSmpcZDs8RR3",
        },
        {
          id: 7,
          src: "https://cdn.filestackcontent.com/Tt1QMGBNRteT45Go1enV",
        },
        {
          id: 8,
          src: "https://cdn.filestackcontent.com/lxFjJi8rSqeY9496SnYt",
        },
        {
          id: 9,
          src: "https://cdn.filestackcontent.com/yl4kBh86T9GIFctI9HgP",
        },
        {
          id: 10,
          src: "https://cdn.filestackcontent.com/Ov2ssoSDClmxRfuBdnEA",
        },
      ],
      challenges: [
        {
          id: 0,
          src: "https://cdn.filestackcontent.com/Ee67gECS8Gt44l4Wx3Nu",
        },
        {
          id: 1,
          src: "https://cdn.filestackcontent.com/BF4JbY4rR2uQLZlHKIma",
        },
        {
          id: 2,
          src: "https://cdn.filestackcontent.com/4kks4kbnTVGYVUtWTUOh",
        },
        {
          id: 3,
          src: "https://cdn.filestackcontent.com/jz02OK5TO69Thg0st29Q",
        },
        {
          id: 4,
          src: "https://cdn.filestackcontent.com/LO9KsDNSSfKwSDZ1ET8w",
        },
        {
          id: 5,
          src: "https://cdn.filestackcontent.com/VcerC5wyT5WGWvmywwxi",
        },
        {
          id: 6,
          src: "https://cdn.filestackcontent.com/KPsQggaQ1mmySCHMb27n",
        },
        {
          id: 7,
          src: "https://cdn.filestackcontent.com/yp7AekcEQiWJNjTO8IDi",
        },
        {
          id: 8,
          src: "https://cdn.filestackcontent.com/IrD3WukhQtyihIyBxZVd",
        },
      ],
      recipes: [
        {
          id: 0,
          src: "https://cdn.filestackcontent.com/hl7bxPFAT96lIcGcPfsJ",
        },
        {
          id: 1,
          src: "https://cdn.filestackcontent.com/UBdZUKpWTFSXXZsPQeXu",
        },
        {
          id: 2,
          src: "https://cdn.filestackcontent.com/wNHLKVxyRoefdXGkWVUl",
        },
        {
          id: 3,
          src: "https://cdn.filestackcontent.com/0DddJp33RnyS80lsaiOg",
        },
        {
          id: 4,
          src: "https://cdn.filestackcontent.com/2zl8BC8fTfatjfJShs18",
        },
        {
          id: 5,
          src: "https://cdn.filestackcontent.com/RuVbtN5uRy2VxOdPo1hx",
        },
        {
          id: 6,
          src: "https://cdn.filestackcontent.com/P41WzUMZQ3qsPNcaFLKZ",
        },
        {
          id: 7,
          src: "https://cdn.filestackcontent.com/jIjyHbCHSamylmNCsLIt",
        },
        {
          id: 8,
          src: "https://cdn.filestackcontent.com/ohN71ua9TSGLcoENnVEs",
        },
        {
          id: 9,
          src: "https://cdn.filestackcontent.com/5HB23lO4QCCZ1Frt5tNs",
        },
        {
          id: 10,
          src: "https://cdn.filestackcontent.com/RsdvdeSJybiJGLrPkFEQ",
        },
        {
          id: 11,
          src: "https://cdn.filestackcontent.com/XLB8DD99QIyIX9Zx1Nbq",
        },
      ],
      carousel: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1904,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      metamorfozy: [
        {
          id: 0,
          name: "Alicja",
          text: `Mam na imię Alicja, lat 42, matka 3 dzieci.
          Dzięki Pauli nauczyłam się jak można zmienić swoje życie na
          lepsze i cieszyć się nim na nowo.
          Z każdym treningiem rośnie poczucie własnej wartości, z każdym
          utraconym kilogramem wiara w siebie, a z każdym centymetrem mniej-
          motywacja do dalszego działania!
          Pamiętajcie, że robicie to dla siebie, żeby czuć się lepiej i im
          więcej od siebie dacie tym lepszy rezultat osiągnięcie!
          `,
          text_footer: `“Jeśli kiedyś będziesz chciała przestać, przypomnij sobie czemu
          zaczynałaś.”`,
          src: "https://cdn.filestackcontent.com/3fl0k17HQbGOCbIs1P8V",
        },
        {
          id: 1,
          name: "Karolina",
          text: `Młoda, aktywna zawodowo mama trójki dzieci.
          Moja przemiana pokazuje, że można stać się całkowicie innym człowiekiem, 
          nie tylko wizualnie, ale również zmienić całkowicie swoje nastawienie do 
          życia.
          Kiedyś byłam zamknięta w sobie, teraz jestem odważną kobietą i idę 
          śmiało przez życie, dążąc do kolejnych życiowych sukcesów.
          `,
          text_footer: ``,
          src: "https://cdn.filestackcontent.com/NzGSk6v3QTfqdcvqFvR9",
        },
        // {
        //   id: 2,
        //   name: "Madzia",
        //   text: `Swoją przygodę z Paulą rozpoczęłam 14 października 2020r.,
        //     była to najlepsza decyzja aby uzyskać lepszą wersję samej siebie.
        //     Po 14 miesiącach jest mnie mniej o minus - 35.6kg i minus- 121 cm.
        //     Dzisiaj nie traktuje swojego nowego życia jako przygodę tylko
        //     codzienność- zdrowe jedzonko i aktywność fizyczna, lepsza forma
        //     i lepsze życie.
        //     To co osiągnęłam mam dzięki Pauli i swojej pracy nad sobą.
        //     Jeśli chcesz zacząć to zacznij od dziś, wstań i nie
        //     czekaj, bo nie ma na co - zdrowy tryb życia jest na wyciągnięcie
        //     ręki.
        //   `,
        //   text_footer: ``,
        //   src: "https://cdn.filestackcontent.com/C0QCEeeUQ9uV6lUWypGA",
        // },
        {
          id: 3,
          name: "Magdalena",
          text: `Mam 46 lat i od niedawna moje życie jest dokładnie takie jakie zawsze chciałam prowadzić. Za mną rok, w którym spełniłam swoje marzenie o szczupłej sylwetce.
                  Zaczęłam 30 listopada 2020 z wagą 84,5 kg … obecnie ważę 69 kg i trening jest nieodłącznym elementem mojego dnia … śmiem sądzić, że trafiałaś w odpowiednie miejsce by podążyć tą samą drogą co ja i osiągnąć ten sam sukces .
          `,
          text_footer: ``,
          src: "https://cdn.filestackcontent.com/5SV4z7F1Rxq8aBhLdLEC",
        },
        {
          id: 4,
          name: "Ania",
          text: `Trafiłam na Paulę 8 tygodni po 
            porodzie szukając cichych ćwiczeń, które będę mogła robić przy
            maluszku. Te programy zmotywowały mnie do regularnych ćwiczeń, 
            a przepisy dały pomysły na zdrowie posiłki i uświadomiły mi, że
            zdrowe jedzenie może być smaczne i proste w przyrządzeniu.
            Zmienił się mój styl życia i myślenia, za co jestem Pauli ogromnie
            wdzięczna... No i za te kilogramy, które zgubiłam też!
          `,
          text_footer: ``,
          src: "https://cdn.filestackcontent.com/a0tLXpgLTbGJdUUgMntc",
        },
        {
          id: 5,
          name: "Anita",
          text: `Uwierz w siebie i zacznij działać już dziś a pokochasz bycie FIT. Zdrowy 
styl życia,  (treningi + proste i smaczne menu) i piękna sylwetka, którą 
wypracujesz w niedługim czasie. Z Paulą świadomie i konsekwentnie 
osiągniesz swój cel. Niezależnie od wieku, wagi zawalcz o lepszą i 
zdrowszą wersje siebie.

          `,
          text_footer: ``,
          src: "https://cdn.filestackcontent.com/g2eFIMxdSsgcA7N1sMab",
        },
      ],
      recipes_carousel: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1904,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      },
      faq: [
        {
          title: "Jak mogę skorzystać z aplikacji?",
          description: `Załóż konto i uzupełnij swoje dane, dzięki temu aplikacja automatycznie 
          dostosuje kaloryczność diety do Twojego zapotrzebowania i zarekomenduje 
          treningi odpowiadające Twoim potrzebom.
          W ramach darmowego dostępu możesz wypróbować kilka treningów i przepisów 
          totalnie za free!
          Mając już konto możesz korzystać z aplikacji gdzie tylko chcesz, zarówno 
          na telefonie jak i komputerze.
          `,
        },
        {
          title: "Dlaczego warto dołączyć?",
          description: `Aplikacja ViveFit zawiera wszystko czego potrzebujesz, aby być w formie.
          Motywujące wyzwania, pyszne przepisy i masę różnorodnych treningów.
          Koniec z przeszukiwaniem internetu, aby znaleźć odpowiedni trening i liczeniem kalorii w
          aplikacjach.
          Tutaj wszystko masz w zasięgu ręki, a Dzienniczek pozwala Ci kontrolować Twoje postępy.
          ViveFit jest z Tobą zawsze i wszędzie gdzie tylko masz dostęp do internetu.`,
        },
        {
          title: "Dopiero zaczynam. Czy sobie poradzę?",
          description: `Oczywiście! W aplikacji ViveFit znajdziesz treningi na każdym poziomie zaawansowania, w tym
            dla osób bardzo początkujących, wracających do formy oraz osób z dużą nadwagą.
            Bez obaw są dla początkujących nie tylko z nazwy ;) to bezpieczne i efektywne ćwiczenia, które
            pomogą Ci zacząć.`,
        },
        {
          title:
            "Nie mam czasu na codzienne treningi, czy mimo to mogę osiągnąć upragnioną sylwetkę?",
          description: `Zdecydowanie tak! W aplikacji znajdują się również ekspresowe treningi, które trwają od
            kilkunastu do 30 minut, więc nawet osoby bardzo zapracowane znajdą tutaj programy dla siebie.
            Wyzwania są elastycznymi planami treningowymi. Jeśli danego dnia nie możesz poćwiczyć to
            śmiało możesz odrobić je w innym terminie- nic Ci nie przepadnie.`,
        },
        {
          title: "W jakiej formie są treningi?",
          description: `Trening to wysokiej jakości video, podczas którego razem z Tobą krok po kroku przechodzimy
          przez każdy etap ćwiczeń.
          Dzięki instruktażowi nie zgubisz się podczas treningu, dokładnie poznasz technikę ćwiczeń i
          poczujesz się jak na sali fitness, a to wszystko w domowym zaciszu.`,
        },
        {
          title: "Jak wygląda dieta z ViveFit?",
          description: `Możesz dowolnie wybierać spośród bogatej listy dań, a aplikacja pomoże 
            Ci w utrzymaniu odpowiedniego bilansu kalorycznego.
            Nie musisz już samodzielnie liczyć kalorii, bo aplikacja zrobi to za 
            Ciebie.
            Przepisy są bardzo smaczne i super łatwe w przygotowaniu.
            Z pewnością każdy znajdzie tu masę inspiracji do zdrowego gotowania. Nie 
            brakuje również propozycji dla wegan oraz dań bez glutenu, bez nabiału 
            czy bez mięsa.
            `,
        },
      ],
    };
  },
  methods: {
    goToPricing: function () {
      document.getElementById("pricing").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  components: {
    AppBarLanding,
    Footer,
    PaymentPlans
  },
  async created() {
    if (this.$store.getters["hasUser"]) {
      this.$router.push("/dashboard");
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400&family=Poppins:wght@500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400&family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400&family=Pacifico&display=swap");
.video-container {
  height: 500px;
  width: 100%;
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  filter: brightness(65%);
}

.video-container .video-overlay {
  z-index: 1;
  position: relative;
  text-align: center;
  padding-top: 120px;

  color: rgb(223, 223, 223);
  font-family: "Poppins", sans-serif;
}

.poppins800 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
.poppins600 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.poppins500 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.montserrat500 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.pacifico {
  font-family: "Pacifico", cursive;
}

.font-size-hero {
  font-size: clamp(1.1rem, 2.5vw, 1.5rem) !important;
  z-index: 1;
}

.font-size-hero-medium {
  font-size: clamp(1rem, 2vw, 1.05rem) !important;
  z-index: 1;
}

.bg-hero {
  background-color: #f9f0ff00 !important;
}

.hero-wave {
  overflow: hidden;
  position: static;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

.hero-wave svg {
  position: absolute;
  z-index: 0;
}

.hero-wave-top {
  top: -1px;
}

.hero-wave-bottom {
  bottom: -1px;
}

.slick-slide .v-image {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
}

.slick-slide:hover {
  transform: scale(1.03);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

@keyframes meta-overlay-animation {
  from {
    top: 10px;
  }
  to {
    top: 1px;
  }
}

.meta-overlay {
  position: absolute;
  top: 1px;
  left: 1px;
  display: none;
  color: rgb(41, 41, 41);
  padding: 10px;
  padding-top: 20px;
  animation-name: meta-overlay-animation;
  animation-duration: 0.2s;
  font-size: 0.8rem;
}

.meta-card:hover .v-image {
  opacity: 0.2;
}

.meta-card:hover .meta-overlay {
  display: block;
  opacity: 1 !important;
}

.meta-name {
  font-size: 1rem;
  font-weight: bold;
  color: rgb(194, 3, 194);
}
</style>
